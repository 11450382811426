import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { navigate } from "@reach/router";
import { v4 } from "uuid";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";

// share
// import SharePartsOfTroublePagePreview from "../share-parts-of-trouble-page";
// import SharePlacesPageTemplate from "../share-places-page";
// import SharePickupNewsPageTemplate from "../share-pickup-news-page";
// import ShareHistoryPageTemplate from "../share-history-page";
import ShareInfomationPageTemplate from "../share-infomation-page";

// images
// import KeyvisualImage from "../../img/trial_hair_growth/medical_monitor_keyvisual.png";
// import MedicalMonitorBanner from "../../img/trial_hair_growth/medical_monitor_banner_02.svg";
// import MedicalFlowImage from "../../img/trial_hair_growth/medical_flow_01.svg";

// data
// import TrialHairGrowthData from "./../../data/trial_hair_growth/trial_hair_growth.json";

// components
// import BarTitle from "../../components/BarTitle";
// import Description from "../../components/Description";
// import GridPanel from "../../components/GridPanel";
// import CautionTextList from "../../components/CautionTextList";
// import BannerHero from "../../components/BannerHero";
// import KeyvisualHero from "../../components/KeyvisualHero";
import SEO from "../../components/seo";
import NoticeContent from "../../components/NoticeContent";

const screenName = "medical-monitor-index";

const onPressToMoveScreenWithQuery = (link) => {
  navigate(`/machines/${link}#prev-${screenName}-screen`);
};

export const MedicalMonitorIndexPageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  return <></>;
};

MedicalMonitorIndexPageTemplate.propTypes = {
  title: PropTypes.string,
};

const MedicalMonitorPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO
        title="毛髪再生セットのモニター募集の終了"
        description="薄毛が気になる方に、おすすめ。薄毛治療のモニターを募集。最先端のCysay社の世界特許取得した唯一の不死化ヒト歯髄幹細胞培養上清液による再生医療です。当院では水光注射を使用し、薄毛治療していきます。"
        keywords="薄毛,ミノキシジル,薄毛治療,育毛,育毛剤"
      />

      <section className="l-section-lv2 l-main-content-hero u-pc-pt00">
        <div className="l-main-content-hero__inner">
          <h2 className="c-notice__title">
            <span className="c-yellow-maker">お知らせ</span>
          </h2>
          <div className={"c-notice-contents"}>
            <NoticeContent
              content={"現在、募集中のモニター施術はありません。"}
              className={"c-notice-contents__inner u-txt-c"}
            />
          </div>
        </div>
      </section>

      {/* <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">{TrialHairGrowthData[0].title}</div>
          </div>
        </div>
      </div> */}

      {/* <KeyvisualHero mt={["60px", "30px"]}>
        <img className="image" src={KeyvisualImage} alt="" />
      </KeyvisualHero> */}

      {/* <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner -pc-space-none">
          <BannerHero>
            <img className="image" src={MedicalMonitorBanner} alt="" />
          </BannerHero>

          <Description className="-top-space">
            <span className="text">
              現在、新たな治療導入のための当院症例モニターを募集しています。
            </span>
            <p className="text">
              ※このモニター募集は事前予告なく終了することがあります。ご了承ください。
            </p>
            <p className="text red">※男性の受付は終了いたしました。</p>
          </Description>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">治療内容</h2>
            </BarTitle>

            <Description className="-top-space">
              <span className="text">
                Cysay社の世界特許取得した唯一の不死化ヒト
              </span>
              <span className="text red">歯髄幹細胞培養上清液</span>
              <span className="text">による再生医療です。当院では</span>
              <a
                href="https://spectacle.notion.site/145f93952d094afa9bdb62e4312ab9b9"
                target="_blank"
                className="text red link"
                rel="noopener noreferrer"
              >
                水光注射※
              </a>
              <span className="text">を使用し、薄毛治療していきます。</span>
            </Description>

            <Description className="-top-space">
              <span className="text">
                ※治療には、国内未承認医薬品または医療機器を⽤いた施術が含まれます。
                承認を受けていない医薬品等について、厚生労働省ホームページに注意点が掲載されておりますので、ご参照ください。
              </span>
              <br />
              <a
                href="https://www.yakubutsu.mhlw.go.jp/individualimport/healthhazard/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                https://www.yakubutsu.mhlw.go.jp/individualimport/healthhazard/
              </a>
            </Description>
          </section>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">治療内容</h2>
            </BarTitle>

            <Description className="-top-space">
              <span className="text">
                薄毛、壮年性脱毛症(AGA)、顔の小じわ、肌荒れ、肩こり、腰痛、手足のしびれ、冷え性、目の疲れ、疲労感、精力減退、不眠、など
              </span>
            </Description>
          </section>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">治療費</h2>
            </BarTitle>

            <Description className="-top-space">
              <span className="text">
                培養上清での治療は、健康保険証の効かない自費診療です。治療内容に応じて、以下の通りの治療費がかかります。
              </span>
            </Description>

            <GridPanel
              className="-top-space"
              headData={[
                {
                  title: "歯髄幹細胞培養上清液\n（水光注射:1回量2cc使用）",
                  text: "￥60,000",
                },
                {
                  title: "タブレット（内服）",
                  text: "￥11,700",
                },
                {
                  title: "リキッド（外用）",
                  text: "￥11,000",
                },
              ]}
              bodyData={[
                {
                  title: " 上記三ヶ月セット（前払い）",
                  text: "￥225,000",
                },
              ]}
            />

            <CautionTextList className="-top-space">
              <p className="icon text">治療回数は4週間毎に1回×3 1クール</p>
              <p className="icon text">
                モニターの方は治療が順調に終了を迎えましたら￥125,000返済いたします。（
                <span className="red">実質￥100,000</span>）
              </p>
              <p className="icon text">
                周期通りの通院ができない場合は正確な経過がみれないため中止となる場合がございますのでご了承ください。
              </p>
              <p className="icon text">全て税込み価格となっております。</p>
            </CautionTextList>
          </section>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">治療の流れについて</h2>
            </BarTitle>

            <BannerHero className="-top-space-m">
              <img className="image" src={MedicalFlowImage} alt="" />
            </BannerHero>
          </section>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">培養上清とは</h2>
            </BarTitle>

            <Description className="-top-space">
              <span className="text">
                「培養上清」とは細胞を培養した際に得られる上澄み部分のことです。つまりこの治療で患者さんに投与されるのは「幹細胞を増殖させている際、培養液中に放出された成分を含んだ上澄み部分」ということです。
              </span>
            </Description>

            <Description className="-top-space-m">
              <span className="text">この「培養上清」は、</span>
              <span className="text red">
                老化した組織や傷ついた組織の再生を助ける目的で開発
              </span>
              <span className="text">
                されたので、治療にあたっては再生医療に精通した担当医師の診察を定期的に受けていただきます。
              </span>
            </Description>

            <Description className="-top-space-m">
              <span className="text">
                使用される培養上清での治療は今回の薄毛治療だけでなく、以下に挙げるような症状の改善にも使用されている薬剤です。
              </span>
            </Description>

            <Description className="-top-space-m">
              <span className="text">
                ただし、効果の程度と効果がみられるまでの期間は各個人によって異なります。※当院はAGAのみ
              </span>
            </Description>
          </section>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">サイトカインとは</h2>
            </BarTitle>

            <Description className="-top-space">
              <span className="text">
                歯髄幹細胞培養上清液に含まれているサイトカイン（成長因子）とは主にタンパク質からできており細胞から生産・分泌される物質です。細胞同士の情報伝達し、免疫細胞を活性化させたり抑制したりする働を持っており、多くの再生医療に使用されています
              </span>
            </Description>
          </section>

          <section>
            <BarTitle className="-top-space">
              <h2 className="title">
                サイトカイン定量分析結果治療効果のある上位５つ
              </h2>
            </BarTitle>

            <CautionTextList className="-top-space">
              <p className="number-icon text fwb">
                Osteoprotegerin オステオプロテゲリン（OPG）
              </p>
              <p className="number-icon text fwb">
                Angiogenin アンギオゲニン（ANG：Aangiogenin）
              </p>
              <p className="number-icon text fwb">
                MMP-1 マトリックスメタロプロテアーゼ（matrix
                metalloproteinase）RANTES ランテス(RANTES)
              </p>
              <p className="number-icon text fwb">
                MMP-3 マトリックスメタロプロテイナーゼ-3
              </p>
            </CautionTextList>

            <Description className="-top-space">
              <p className="text">
                ※このモニター募集は事前予告なく終了することがあります。ご了承ください。
              </p>
              <p className="text red">※男性の受付は終了いたしました。</p>
            </Description>
          </section>
        </div>
      </section> */}

      {/* <a
        href="tel:08008051127"
        className="c-float-bottom-button -click-on-pc-only"
      >
        <div className="c-float-bottom-button__inner">
          <p className="c-float-bottom-button__text u-txt-c">
            お問い合わせ
            <span className="c-float-bottom-button__subtext u-sp-dis-n">
              TEL: 08008051127
            </span>
          </p>
        </div>
      </a> */}
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

MedicalMonitorPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default MedicalMonitorPage;

export const medicalMonitorPageQuery = graphql`
  query MedicalMonitorPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
